class Header {
    constructor(settings) {

        const defaultSettings = {
            headerElement: document.getElementById('masthead'),
            fixedHeaderOffset: 30,
            menuButton: document.querySelector('.hamburger'),
            menuElement: document.querySelector('.main-menu'),
            subMenuElements: document.querySelectorAll('#masthead .menu-item-has-children.trigger-menu'),
            closeSubMenuButton: document.querySelector('.close-submenu'),
            debug: false,
            logoNode: document.querySelector('.header-logo'),
            logoImageNode: document.querySelector('.header-logo img'),
        }

        // set default args if none are provided
        // s = settings
        arguments.length < 1 ? this.s = defaultSettings : this.s = settings;

        // log settings if debug is true
        !!this.s.debug ? this.logSettings() : null;

        // listener to hamburger button click
        this.s.menuButton.addEventListener('click', this.toggleMenu.bind(this))

        // Submenu listeners
        // this.s.subMenuElements !== null ?
        //     this.s.subMenuElements.forEach(submenu => {
        //         submenu.addEventListener('click', this.openSubMenu.bind(this));
        //     }) : null;

        // Submenu close button listener
        // this.s.closeSubMenuButton.addEventListener('click', this.closeSubMenu.bind(this));

        // fixed menu
        // window.onscroll = this.becomeFixed.bind(this);
        // this.becomeFixed();

        // this.s.searchButtonNode.addEventListener('click', this.showHeaderSearch.bind(this));
    }

    logSettings() {
        console.log(this.s);
    }

    toggleMenu() {

        this.s.menuButton.classList.toggle('is-active');
        this.s.menuElement.classList.toggle('is-active');
        this.s.headerElement.classList.toggle('menu-open');

        document.querySelectorAll('body, html').forEach(item => {
            item.classList.toggle('no-scroll');
        });

        if (this.s.headerElement.classList.contains('submenu-open')) {
            this.s.headerElement.classList.remove('submenu-open');
        }

    }

    openSubMenu(event) {
        // event.preventDefault()

        // get all children
        // const childNodes = event.target.childNodes
        const li = event.target.parentNode;
        const ul = li.querySelector('.sub-menu');

        // class handling
        // document.querySelectorAll('.sub-menu.is-open').removeClass('is-open');
        ul.classList.add('is-open');
        this.s.headerElement.classList.toggle('submenu-open');
    }

    closeSubMenu() {
        this.s.headerElement.classList.remove('submenu-open');
        const openItems = document.querySelectorAll('.sub-menu.is-open');

        openItems.forEach(openItem => {
            openItem.classList.remove('is-open');
        });
    }

    becomeFixed() {
        const { templateURL, frontPage } = helpers.wpVars;

        if (frontPage) {
            if (window.pageYOffset > this.s.fixedHeaderOffset) {
                this.s.headerElement.classList.add('inner-header');
            } else {
                this.s.headerElement.classList.remove('inner-header');
            }
        } else {
            if (window.pageYOffset > this.s.fixedHeaderOffset) {
                this.s.headerElement.classList.add('inner-header');
            } else {
                this.s.headerElement.classList.remove('inner-header');
            }
        }

    }

    showHeaderSearch(e) {
        let searchformNode = e.target.parentNode;

        if (!searchformNode.classList.contains('is-open')) {
            e.preventDefault();
        }

        searchformNode.classList.add('is-open');
        searchformNode.querySelector('input[name="s"]').focus()
    }
}